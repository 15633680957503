import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const config = {
  apiKey: "AIzaSyBOn8feXpE8LkpeasKkJTHhoSKjsMcazdE",
  authDomain: "xortd-chat.firebaseapp.com",
  databaseURL: "https://xortd-chat-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "xortd-chat",
  storageBucket: "xortd-chat.appspot.com",
  messagingSenderId: "116934653097",
  appId: "1:116934653097:web:f7cec535304294532225b8",
  measurementId: "G-66XZPPR03J"
};

const app = initializeApp(config);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lc2GXEcAAAAAH2rIqyVjOJaW2gN33xNYSCw2UIJ'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export const auth = getAuth();
export const db = getDatabase();
