import React, {useState, useMemo, useEffect, useRef} from "react";
import Header from "../components/Header";
import {auth} from "../services/firebase";
import {db} from "../services/firebase";
import {ref, set, onValue, push, onChildAdded, orderByChild, query, startAt} from "firebase/database";
import {ChatController, MuiChat} from "chat-ui-react";
import {CssBaseline, Divider, makeStyles} from "@material-ui/core";
import {useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: 'gray',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: theme.palette.background.default,
    },
    header: {
        padding: '1rem',
    },
    chat: {
        flex: '1 1 0%',
        minHeight: 0,
        marginTop: '3.5rem'
    },
}));

export default function ChatV2() {
    const classes = useStyles();
    const [chatCtl] = useState(new ChatController({delay: 150}));
    const [currentUser] = useState(auth.currentUser);
    const {chatId = "default" } = useParams()

    const buildContent = (appendUserName, userName, content) => {
        return appendUserName ? `${userName} said:\n${content}` : content;
    }

    const chat = `chat/${chatId}`;

    // Read all previous data
    const chatCountRef = ref(db, chat);
    onValue(chatCountRef, snapshot => {
        const chats = [];

        snapshot.forEach(child => {
            const previousUser = chats[chats.length-1]?.userId;

            chats.push({
                type: 'text',
                content: buildContent(child.val().uid !== currentUser.uid && (previousUser === currentUser.uid || !previousUser), child.val().userName, child.val().content),
                self: child.val().uid === currentUser.uid,
                createdAt: new Date(child.val().timestamp),
                userId: child.val().uid,
            })
        })

        chatCtl.setMessages(chats);

        console.log(chatCtl.getMessages())
        // messagesEnd?.current && messagesEnd.current.scrollIntoView({ behavior: "smooth" });
        window.scrollTo(0, document.body.scrollHeight);
    }, {
        onlyOnce: true
    });

    // Listen for new messages but only write from other users
    // The chat framework adds own messages
    const chatCountRefNewMessages = query(chatCountRef, orderByChild('timestamp'), startAt(Date.now()))
    onChildAdded(chatCountRefNewMessages, async (data) => {
        if (data.val().uid !== currentUser.uid) {
            const messages = chatCtl.getMessages();
            const lastMessage = messages[messages.length-1];
            console.log(messages)

            await chatCtl.addMessage({
                type: 'text',
                content: buildContent(lastMessage?.userId !== data.val().uid, data.val().userName, data.val().content),
                self: false,
                userId: data.val().uid,
            })
        }
    });

    useMemo(async () => {
        // Chat content is displayed using ChatController
        await chatCtl.setActionRequest({
            type: 'text',
            always: true,
        }, (response) => {
            const chatRef = ref(db, chat);
            const newMessage = push(chatRef);
            const email = currentUser.email;

            chatCtl.updateMessage(chatCtl.getMessages().length - 1, {
                type: 'text',
                content: response.value,
                self: true,
                userId: currentUser.uid,
            });

            set(newMessage, {
                content: response.value,
                timestamp: Date.now(),
                uid: currentUser.uid,
                displayName: currentUser.displayName,
                userName: email.substring(0, email.indexOf('@')),
            });
        });
    }, [chatCtl]);

    // Only one component used for display
    return <>
        <CssBaseline/>
        <div className={classes.root}>
            <div className={classes.container}>
                <Header classNAme={classes.header}/>
                <Divider/>
                <div className={classes.chat}>
                    <MuiChat chatController={chatCtl}/>
                </div>
            </div>
        </div>
    </>;
}
